<template>
	<div class="group-list">
		<!-- <van-sticky>
			<filter-top @get="getData"></filter-top>
		</van-sticky> -->

		<no-data v-if="list.length<=0"></no-data>
		<van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh" v-else>
			<van-list finished-text="加载完毕" v-model="isloading" :finished="finished" @load="loadData">
				<div class="goodslist">

					<div class="goods-container" v-for="(item,index) in list" :key="index">
						<div class="goods-wrap" @click="toDetail(item.id)">
							<van-cell title-class="top-cell flex flex-row">
								<template slot="title">

									<van-image width="3rem" height="3rem" :src="item.images" fit="cover">
										<template v-slot:loading>
											<van-loading type="spinner" size="20" />
										</template>
									</van-image>


									<div class="text-wrap">
										<div class="name-wrap clamp-1">
											<span class="text-theme1" style="font-size: 14px;">{{item.goods_info.moldbaby_name}}</span>
										</div>
										<div class="brand-wrap clamp-1">
											<span class="text-base">{{item.goods_info.moldbady_desc}}</span>
										</div>
										<div class="price-wrap clamp-1">
											<span class="text-theme1" style="color: #f6a307;">￥{{item.goods_info.real_price}}</span>
											<span class="text-grey">￥{{item.goods_info.retail_price}}</span>
										</div>
										<div class="price-wrap clamp-1" style="display: flex;justify-content:space-between">
											<span>
												<span class="text-theme1">定金：</span>
												<span style="color: red;">￥{{item.deposit_price}}</span>
											</span>
											<span :style="item.status == 2?'background-color: #09bb07;':'background-color: #1ca7ff;'" style="padding: 7px;color: white;border-radius: 50% 0 0 50%;">{{item.status_text}}</span>
										</div>
										
										
										
									</div>

								</template>
							</van-cell>
						</div>
							
					</div>

				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
	import {
		DropdownMenu,
		DropdownItem,
		List,
		Row,
		Col,
		Panel,
		Button,
		Search,
		Icon,
		Overlay,
		Picker,
		Popup,
		Field,
		Cell,
		CellGroup,
		DatetimePicker,
		Uploader,
		SwitchCell,
		Checkbox,
		Toast,
		Sticky,
		PullRefresh,
		Loading,
		Progress
	} from "vant";
    import {
        user_marketing_hot_orderlist,//爆款列表
    } from "@/request/api";
	export default {
		components: {
			[DropdownMenu.name]: DropdownMenu,
			[DropdownItem.name]: DropdownItem,
			[List.name]: List,
			[Row.name]: Row,
			[Col.name]: Col,
			[Panel.name]: Panel,
			[Button.name]: Button,
			[Search.name]: Search,
			[Icon.name]: Icon,
			[Overlay.name]: Overlay,
			[Popup.name]: Popup,
			[Field.name]: Field,
			[Picker.name]: Picker,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[DatetimePicker.name]: DatetimePicker,
			[Uploader.name]: Uploader,
			[SwitchCell.name]: SwitchCell,
			[Checkbox.name]: Checkbox,
			[Toast.name]: Toast,
			[Sticky.name]: Sticky,
			[PullRefresh.name]: PullRefresh,
			[Loading.name]: Loading,
			[Progress.name]: Progress
		},
		data() {
			return {
				page: 1,
				size: 10,
				list: [],
				isLoading: false,
				finished: false,
				isloading: false,
				activity_id: ''
			}
		},
        mounted: function() {
            this.activity_id = this.$route.query.activity_id;
            this.loadData();
        },
		methods: {
            async loadData(){
                if (this.finished) {
                    return;
                }
                if(this.isloading){
                    return;
                }
                this.isloading = true;
                // 获取订单列表
                await user_marketing_hot_orderlist({rid: this.activity_id,page: this.page,aid: this.activity_id}).then(res => {
                    if(res.code == 1){
                        //  还有更多数据page++
                        if (res.data.has_more == true){
                            this.page++;
                            this.finished = false;
                        } else {
                            //没有数据了
                            this.finished = true
                        }
						var lists = res.data.data
						for (var i = 0; i < lists.length; i++) {
							lists[i].goods_info = JSON.parse(lists[i].goods_info)
						}
                        this.list = this.list.concat(lists)
                    } else {
                        this.$dialog({message:res.msg});
                    }
                }).catch(err => {
                    this.$dialog({
                        message: "爆款数据获取失败，请重新刷新"
                    })
                });

                // 加载状态结束
                this.loading = false;
                this.isloading = false
			},
			onRefresh(){
                this.isLoading = false;
                this.finished = false;
                this.list = [];
                this.page = 1;
                this.loadData();
			},
			toDetail(id){
                this.$router.push({path:'/activity/marketing/hot/orderDetail',query:{id:id}})
			}
		}
	}
</script>

<style lang="less" scoped>
	.group-list {
		.APP-field {
			width: 96%;
			padding: 2%;
			font-size: 12px;
			color: #757575;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
		}

		.APP-field>div {
			width: 47%;
			text-align: center;
			padding: 10px 0;
			border: 1px solid #e7e7e7;
		}

		.top-button-wrap {
			display: flex;
			flex-flow: row nowrap;
			padding: 10px 3px;
			justify-content: center;

			button {
				width: 100px;
			}
		}


		// 公共商品样式
		.goods-wrap {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			background-color: white;
			font-size: 0;
			overflow: hidden;

			.van-image {
				margin-right: 10px;
				border-radius: 5%;
			}

			.text-wrap {
				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;
				height: 3rem;
				flex: 1;

				font-size: 0;

				span {
					font-size: 12px;

				}

				.brand-wrap {
					flex: 1;
					// height: 17px;
				}

				.name-wrap {
					flex: 1;

					// height: 17px;
					.text-base {
						// font-size: 14px;
						// font-weight: bold
					}
				}

				.desc-wrap {
					// height: 35px;
					flex: 2;
					margin-bottom: 5px;
				}

				.price-wrap {
					// height: 17px;
					flex: 1;
					display: flex;
					flex-flow: row nowrap;
					align-items: center;

					.text-grey {
						text-decoration: line-through;
					}

					.text-theme3 {
						display: block;
						font-size: 18px;
						// margin-left: 5px;
					}
				}
			}


		}
		.Add-wrap {
		  position: fixed;
		  bottom: 20px;
		  right: 15px;
		  font-size: 45px;
		  color: #1989fa;
		}
	}
</style>
